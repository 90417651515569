






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Configuration, TitleTemplatePublic, RedCapital, SelectedCountry, RedCapitalConfig } from '@/configuration'
import API from '@/api'
import { EventBus } from '@/main'
import DniField from '@/components/DniField.vue'
import { DniTrim } from '@/utils'

@Component({
  metaInfo: {
    title: 'Recuperar contraseña',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data(){
    return{
      valid: false,
      canPress: true,
      dni: '',
      dialog: false,
      errorMessage: null,
    }
  },
  watch:{
    dni() {
      this.$data.errorMessage = null
      // @ts-ignore
      this.$data.valid = this.$refs.form.validate()
    },
  },
  methods:{
    validate(e) {
      e.preventDefault()
      this.$data.canPress = false
      API.recover(DniTrim.string(this.$data.dni)).then( data => {
        this.$data.dialog = true
        setTimeout(() => {
          this.$router.push({
            path: '/'
          })
        }, 2500)
      }).catch( res => {
        this.$data.canPress = true
        this.$data.errorMessage = res.message
        EventBus.$emit('snack-error', this.$data.errorMessage)
      })
    }
  },
  components: {
    DniField,
  },
})
export default class Recover extends Vue {}
